import React, { Component } from "react";

class Contacts extends Component {
  render() {
    return (
      <div className="relative bg-white max-w-6xl mx-auto mt-24">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 left-0 w-1/2" />
        </div>
        <div className="relative w-full lg:grid lg:grid-cols-5">
          <div className="py-16 lg:col-span-2 lg:py-24 xl:pr-12">
            <div className="mx-auto max-w-lg">
              <h2 className="text-xl font-bold tracking-tight text-gray-900 space-mono-bold">Get in touch</h2>
              <p className="mt-3 text-md leading-6 text-gray-500 space-mono-regular">
                Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus
                arcu.
              </p>
              <dl className="mt-8 text-sm text-gray-800 space-mono-regular">
                <div>
                  <dt className="sr-only">Postal address</dt>
                  <dd>
                    <p>27 School Lane</p>
                    <p>Westlands, NBO, Kenya</p>
                  </dd>
                </div>
                <div className="mt-6 text-sm">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex">
                    <span className="">+254 715532315</span>
                  </dd>
                </div>
                <div className="mt-3 text-sm">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <span className="">support@soldoutafrica.com</span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-white py-16 px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="mx-auto max-w-lg lg:max-w-none">
              <form action="#" method="POST" className="grid grid-cols-1 gap-y-6 space-mono-regular">
                <div>
                  <label htmlFor="full-name" className="sr-only">
                    Full name
                  </label>
                  <input
                    type="text"
                    name="full-name"
                    id="full-name"
                    autoComplete="name"
                    className="outline-none w-full min-w-0 appearance-none rounded-xl border border-gray-100 bg-white px-[calc(theme(spacing.4)-1px)] py-[calc(theme(spacing[2])-1px)] text-base leading-7 text-gray-900 placeholder-gray-400 shadow-sm focus:border-gray-800 focus:ring-pink-600 sm:w-64 sm:text-sm sm:leading-6 xl:w-full"
                    placeholder="Full name"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="outline-none w-full min-w-0 appearance-none rounded-xl border border-gray-100 bg-white px-[calc(theme(spacing.4)-1px)] py-[calc(theme(spacing[2])-1px)] text-base leading-7 text-gray-900 placeholder-gray-400 shadow-sm focus:border-gray-800 focus:ring-pink-600 sm:w-64 sm:text-sm sm:leading-6 xl:w-full"
                    placeholder="Email"
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="sr-only">
                    Phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="tel"
                    className="outline-none w-full min-w-0 appearance-none rounded-xl border border-gray-100 bg-white px-[calc(theme(spacing.4)-1px)] py-[calc(theme(spacing[2])-1px)] text-base leading-7 text-gray-900 placeholder-gray-400 shadow-sm focus:border-gray-800 focus:ring-pink-600 sm:w-64 sm:text-sm sm:leading-6 xl:w-full"
                    placeholder="Phone"
                  />
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    className="outline-none w-full min-w-0 appearance-none rounded-xl border border-gray-100 bg-white px-[calc(theme(spacing.4)-1px)] py-[calc(theme(spacing[2])-1px)] text-base leading-7 text-gray-900 placeholder-gray-400 shadow-sm focus:border-gray-800 focus:ring-pink-600 sm:w-64 sm:text-sm sm:leading-6 xl:w-full"
                    placeholder="Message"
                    defaultValue={''}
                  />
                </div>
                <div>
                <button
                      type="submit"
                      className="uppercase text-xs py-2.5 px-6 border border-gray-200 rounded-full text-black font-semibold hover:text-white hover:bg-gradient-to-r from-pink-500 to-purple-800 transition ease-in">
                      Submit
                    </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contacts;
