import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Scrollbar } from 'swiper/modules';
import "swiper/css";
import "swiper/css/scrollbar";
import { useNavigate } from "react-router-dom";

const events = [
  {
    id: 1,
    title: "TechForward Summit",
    description:
      "An annual conference for technology enthusiasts, industry professionals, and innovators. The TechForward Summit dives into the future of tech trends, from AI and cybersecurity to the latest in sustainable tech solutions. This event offers keynotes from top tech leaders, hands-on workshops, and networking opportunities with like-minded professionals passionate about driving technology forward.",
    imageUrl: "https://www.eventbookings.com/wp-content/uploads/2023/06/Colorful-Dance-Party-Night-Poster-.jpg",
  },
  {
    id: 2,
    title: "Innovate & Inspire: Startup Showcase",
    description:
      "A platform for ambitious startups to showcase their groundbreaking ideas and innovations. Meet potential investors, network with industry experts, and discover what's next in the world of entrepreneurship and innovation.",
    imageUrl: "https://www.eventbookings.com/wp-content/uploads/2023/06/Red-Illustrative-Punk-Skull-Underground-Rock-Music-Band-Poster.jpg",
  },
  {
    id: 3,
    title: "Global Wellness & Mindfulness Expo",
    description:
      "Discover the latest trends in wellness and mindfulness at this global expo. From yoga sessions to mental health workshops, this event promotes a holistic approach to personal and professional well-being.",
    imageUrl: "https://www.eventbookings.com/wp-content/uploads/2023/06/White-Black-Red-Modern-Art-Exhibition-Poster-1.jpg",
  },
  {
    id: 4,
    title: "Sustainability & Green Tech Conference",
    description:
      "Join the leading minds in sustainability and green technology to explore innovations driving eco-friendly solutions. Dive into discussions on renewable energy, waste management, and sustainable practices for a greener future.",
    imageUrl: "https://www.eventbookings.com/wp-content/uploads/2023/06/Light-Green-Pumpkin-Carving-Contest-Halloween-Poster.jpg",
  },
  {
    id: 5,
    title: "Future of Education Summit",
    description:
      "A forward-thinking event exploring how technology and innovation are transforming education. Engage in discussions about AI in learning, remote education strategies, and tools shaping the classroom of tomorrow.",
    imageUrl: "https://www.eventbookings.com/wp-content/uploads/2023/06/Pop-up-with-colours.jpg",
  },
];

const HomeHero = () => {
  const [activeCard, setActiveCard] = useState(0);
  const swiperRef = useRef(null);
  const navigate = useNavigate();
  const intervalRef = useRef(null);

  useEffect(() => {
    // Initialize interval for automatic card sliding
    intervalRef.current = setInterval(() => {
      setActiveCard((prev) => {
        const nextCard = prev < events.length - 1 ? prev + 1 : 0;
        // Ensure swiperRef.current is defined before calling slideTo
        if (swiperRef.current && swiperRef.current.swiper) {
          swiperRef.current.swiper.slideTo(nextCard);
        }
        return nextCard;
      });
    }, 4000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const handleViewEvent = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  return (
    <div className="relative max-w-6xl mx-auto">
      <div className="grid grid-cols-12 place-content-center max-w-6xl">
        <div className="col-span-12 flex flex-col gap-6 md:gap-10 md:text-left text-center">
            <div className="flex flex-col items-center md:items-start gap-4 lg:gap-8 py-6">
              <div className="text-3xl lg:w-4/5 text-gray-800 dm-sans">
                Top Events
              </div>
            </div>
        </div>
        <div className="block md:hidden col-span-12 w-full">
          <Swiper
            slidesPerView={'1'}
            spaceBetween={10}
            modules={[Autoplay, Scrollbar]}
            grabCursor={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            centeredSlides={true}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
          >
            {events.map((event, index) => (
              <SwiperSlide
                className={`swiper-slide text-white font-medium transition-transform duration-300`}
                key={index}
              >
                <div className="grid place-content-center">
                  <img
                    src={event.imageUrl}
                    alt={event.title}
                    className="w-auto h-96 object-fill "
                  />
                  <div className="flex gap-2 items-center space-mono-regular justify-start pt-4">
                    <button
                      className="uppercase text-xs py-2.5 px-6 border border-gray-200 rounded-full text-black font-semibold hover:text-white hover:bg-gradient-to-r from-pink-500 to-purple-800 transition ease-in"
                      onClick={() => handleViewEvent(event.id)}
                    >
                      View Details
                    </button>
                    <button className="uppercase text-xs py-2.5 px-6 border border-gray-200 rounded-full text-black font-semibold hover:text-white hover:bg-gradient-to-r from-pink-500 to-purple-800 transition ease-in">
                      Get Ticket
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="hidden md:block lg:hidden col-span-12 w-full">
          <Swiper
            slidesPerView={'2.5'}
            spaceBetween={30}
            modules={[Autoplay, Scrollbar]}
            grabCursor={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
          >
            {events.map((event, index) => (
              <SwiperSlide
                className={`swiper-slide text-white font-medium transition-transform duration-300`}
                key={index}
              >
                <div onClick={() => console.log("dshdufsudusiad", event.title)}>
                  <img
                    src={event.imageUrl}
                    alt={event.title}
                    className="w-auto h-96 object-fill "
                  />
                  <div className="flex gap-2 items-center space-mono-regular justify-start pt-4">
                    <button
                      className="uppercase text-xs py-2.5 px-6 border border-gray-200 rounded-full text-black font-semibold hover:text-white hover:bg-gradient-to-r from-pink-500 to-purple-800 transition ease-in"
                      onClick={() => handleViewEvent(event.id)}
                    >
                      View Details
                    </button>
                    <button className="uppercase text-xs py-2.5 px-6 border border-gray-200 rounded-full text-black font-semibold hover:text-white hover:bg-gradient-to-r from-pink-500 to-purple-800 transition ease-in">
                      Get Ticket
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="hidden lg:block col-span-12 w-full">
          <Swiper
            slidesPerView={'3.5'}
            spaceBetween={30}
            modules={[Autoplay, Scrollbar]}
            grabCursor={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
          >
            {events.map((event, index) => (
              <SwiperSlide
                className={`swiper-slide text-white font-medium transition-transform duration-300`}
                key={index}
              >
                <div onClick={() => console.log("dshdufsudusiad", event.title)}>
                  <img
                    src={event.imageUrl}
                    alt={event.title}
                    className="w-auto h-96 object-fill "
                  />
                  <div className="flex gap-2 items-center space-mono-regular justify-start pt-4">
                    <button
                      className="uppercase text-xs py-2.5 px-6 border border-gray-200 rounded-full text-black font-semibold hover:text-white hover:bg-gradient-to-r from-pink-500 to-purple-800 transition ease-in"
                      onClick={() => handleViewEvent(event.id)}
                    >
                      View Details
                    </button>
                    <button className="uppercase text-xs py-2.5 px-6 border border-gray-200 rounded-full text-black font-semibold hover:text-white hover:bg-gradient-to-r from-pink-500 to-purple-800 transition ease-in">
                      Get Ticket
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
