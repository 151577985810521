import React, { useState, useEffect } from "react";
import qr from '../../assets/img/qr-code.png';
import { useNavigate, useParams } from "react-router-dom";
import getRequest from "../../fetch/api";

const paymentMethods = [
  { id: 1, name: "Lipa na M-PESA", content: "Pay conveniently with M-Pesa." },
  { id: 2, name: "Credit or Debit Card", content: "Securely pay using your card." },
  { id: 3, name: "Pay with PayPal", content: "Use PayPal for fast and secure payments." },
];

const EventPurchasePage = () => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTickets, setSelectedTickets] = useState([]);

  const { eventId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(eventId)
    // Fetch event details
    fetchEventDetails();
  }, [eventId]);

  const fetchEventDetails = async () => {
    try {
      const res = await getRequest(`/event/get?eventId=${eventId}`);
      // console.log(res.data.event);
      const event = res.data.event
      setEvent(event);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching event:", error);
      setError("Failed to load event details");
      setLoading(false);
    }
  };

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  const getTabClass = (index) =>
    `tab text-gray-500 space-mono-bold ${selectedTab === index ? "text-black font-bold" : ""}`;

  const updateTicketQuantity = (ticket, type) => {
    setSelectedTickets((prevSelectedTickets) => {
      const existingTicketIndex = prevSelectedTickets.findIndex((t) => t.id === ticket.id);

      if (existingTicketIndex !== -1) {
        const updatedTickets = [...prevSelectedTickets];
        const existingTicket = updatedTickets[existingTicketIndex];

        if (type === "increment") {
          existingTicket.quantity += 1;
        } else if (type === "decrement") {
          existingTicket.quantity -= 1;
          // Remove ticket if quantity is 0
          if (existingTicket.quantity <= 0) {
            updatedTickets.splice(existingTicketIndex, 1);
          }
        }

        return updatedTickets;
      } else if (type === "increment") {
        // Add new ticket with quantity 1
        return [...prevSelectedTickets, { ...ticket, quantity: 1 }];
      }

      return prevSelectedTickets;
    });
  };
  const removeTicket = (ticketId) => {
    setSelectedTickets((prevSelectedTickets) =>
      prevSelectedTickets.filter((ticket) => ticket.id !== ticketId)
    );
  };
  const getTicketQuantity = (ticketId) => {
    const ticket = selectedTickets.find((t) => t.id === ticketId);
    return ticket ? ticket.quantity : 0;
  };

  const calculateSubtotal = () =>
    selectedTickets.reduce((total, ticket) => total + ticket.ticketPrice * ticket.quantity, 0);

  const charges = 50; // Flat shipping rate
  const subtotal = calculateSubtotal();
  const taxes = (subtotal * 0.16).toFixed(2); // Example 16% tax rate
  const total = (subtotal + charges + parseFloat(taxes)).toFixed(2);

  if (loading) {
    return (
      <div className="py-24 h-[70vh] max-w-4xl dm-sans mx-auto text-center flex flex-col items-center justify-center">
        <span className="loading loading-ring loading-4xl text-purple-800"></span>
        <p>Loading event details...</p>
      </div>
    )
  }

  if (error) {
    return (
      <div className="py-24 h-[70vh] max-w-4xl dm-sans mx-auto text-center flex flex-col items-center justify-center">
        <lord-icon
          src="https://cdn.lordicon.com/lltgvngb.json"
          trigger="in"
          delay="1500"
          stroke="light"
          state="hover-oscillate"
          colors="primary:#9947b0,secondary:#da2877"
          style={{ width: "100px", height: "100px" }}
        ></lord-icon>
        <p className="text-red-500 space-mono-regular">{error}</p>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 px-4">
      <div className="mx-auto max-w-2xl pt-14 pb-24 lg:max-w-6xl">
        <h2 className="sr-only">Checkout</h2>
        <div className="uppercase space-mono-bold relative rounded-full py-1 px-1 text-sm leading-6 text-purple-500">
          {event.eventLocation}
        </div>
        <p className="mt-1 mb-8 font-bold tracking-tight text-gray-900 dm-sans text-3xl">
          {event.eventName}
        </p>
        <form className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
          {/* Left Column */}
          <div>
            <div className="space-mono-regular">
              <h2 className="text-lg font-medium text-gray-900 space-mono-bold pb-2">
                Contact information
              </h2>
              <div className="grid grid-cols-12 space-x-6">
                <div className="col-span-6">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    First name
                  </label>
                  <input
                  required
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="first name"
                    className="outline-none w-full min-w-0 appearance-none rounded-xl border border-gray-100 bg-white px-4 py-3 text-base leading-7 text-gray-900 placeholder-gray-400 shadow-sm focus:border-gray-800 focus:ring-pink-600 sm:w-64 sm:text-sm sm:leading-6 xl:w-full"
                  />
                </div>
                <div className="col-span-6">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Last name
                  </label>
                  <input
                  required
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="last name"
                    className="outline-none w-full min-w-0 appearance-none rounded-xl border border-gray-100 bg-white px-4 py-3 text-base leading-7 text-gray-900 placeholder-gray-400 shadow-sm focus:border-gray-800 focus:ring-pink-600 sm:w-64 sm:text-sm sm:leading-6 xl:w-full"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Email address
                </label>
                <input
                required
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="outline-none w-full min-w-0 appearance-none rounded-xl border border-gray-100 bg-white px-4 py-3 text-base leading-7 text-gray-900 placeholder-gray-400 shadow-sm focus:border-gray-800 focus:ring-pink-600 sm:w-64 sm:text-sm sm:leading-6 xl:w-full"
                />
              </div>
            </div>

            {/* Ticket Selection */}
            <div className="mt-10 border-t border-gray-200 pt-10">
              <div className="space-mono-regular">
                <h2 className="text-lg font-medium text-gray-900 space-mono-bold pb-2">Ticket Selection</h2>
                <div className="overflow-x-auto">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th className="text-center">Cost (KES)</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {event.tickets.map((ticket) => (
                        <tr key={ticket.id}>
                          <td>{ticket.ticketName}</td>
                          <td className="text-center">{ticket.ticketPrice}</td>
                          <td className="flex items-center gap-4 w-full justify-center">
                            <button
                              type="button"
                              className="btn btn-square btn-md text-2xl"
                              onClick={() => updateTicketQuantity(ticket, "decrement")}
                              disabled={ticket.isSoldOut || getTicketQuantity(ticket.id) === 0}
                            >
                              -
                            </button>
                            <div>{getTicketQuantity(ticket.id)}</div>
                            <button
                              type="button"
                              className="btn btn-square btn-md btn-outline text-2xl"
                              onClick={() => updateTicketQuantity(ticket, "increment")}
                              disabled={ticket.isSoldOut}
                            >
                              +
                            </button>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td className="text-gray-800 font-semibold">Subtotal</td>
                        <td></td>
                        <td className="flex items-center gap-4 justify-center py-4">
                          KES {subtotal.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-gray-800 font-semibold">Charges</td>
                        <td></td>
                        <td className="flex items-center gap-4 justify-center py-4">
                          KES {charges.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-gray-800 font-semibold">Taxes</td>
                        <td></td>
                        <td className="flex items-center gap-4 justify-center py-4">
                          KES {taxes}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-gray-800 font-semibold">Total</td>
                        <td></td>
                        <td className="flex items-center gap-4 justify-center py-4">
                          KES {total}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* mobile: Order Summary */}
          <div className="block md:hidden mt-10 lg:mt-0">
            <h2 className="text-lg font-medium text-gray-900 space-mono-bold pb-3">Order Summary</h2>
            <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm space-mono-regular">
              <h3 className="sr-only">Tickets in your cart</h3>
              <ul role="list" className="divide-y divide-gray-200">
                {selectedTickets.map((ticket) => (
                  <li key={ticket.id} className="flex py-6 px-4 sm:px-6">
                    <div className="flex-shrink-0">
                      <img
                        src={ticket.imageSrc}
                        alt={ticket.imageAlt}
                        className="w-20 rounded-md"
                      />
                    </div>

                    <div className="ml-6 flex flex-1 flex-col">
                      <div className="flex">
                        <div className="min-w-0 flex-1">
                          <h4 className="text-sm">
                            <span className="font-medium text-gray-700 space-mono-bold">
                              {ticket.ticketName}
                            </span>
                          </h4>
                          <p className="mt-1 text-sm text-gray-500">
                            Quantity: {ticket.quantity}
                          </p>
                        </div>

                        <div className="ml-4 flow-root flex-shrink-0">
                          <button
                            type="button"
                            className="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500"
                            onClick={() => removeTicket(ticket.id)}
                          >
                            <span className="sr-only">Remove</span>
                            🗑️
                          </button>
                        </div>
                      </div>

                      <div className="flex flex-1 items-end justify-between pt-2">
                        <p className="mt-1 text-sm font-medium text-gray-900">
                          KES {ticket.ticketPrice * ticket.quantity}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>

              <dl className="space-y-6 border-t border-gray-200 py-6 px-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <dt className="text-sm">Subtotal</dt>
                  <dd className="text-sm font-medium text-gray-900">KES {subtotal.toFixed(2)}</dd>
                </div>
                <div className="flex items-center justify-between">
                  <dt className="text-sm">Charges</dt>
                  <dd className="text-sm font-medium text-gray-900">KES {charges.toFixed(2)}</dd>
                </div>
                <div className="flex items-center justify-between">
                  <dt className="text-sm">Taxes</dt>
                  <dd className="text-sm font-medium text-gray-900">{taxes}</dd>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt className="text-base font-medium">Total</dt>
                  <dd className="text-base font-medium text-gray-900">KES {total}</dd>
                </div>
              </dl>

              <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                <button type="button" className="btn bg-white space-mono-bold w-full uppercase text-sm py-2.5 px-6 border border-gray-200 rounded-xl text-black font-semibold hover:text-white hover:bg-gradient-to-r from-pink-500 to-purple-800 transition ease-in"
                  onClick={() => {
                    const element = document.getElementById('proceed-payment');
                    if (element) {
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}>Go to payment</button>
              </div>
            </div>
          </div>

            {/* Payment */}
            <div className="mt-10 border-t border-gray-200 pt-10" id='proceed-payment'>
              <h2 className="text-lg font-medium text-gray-900 space-mono-bold pb-3">
                Payment
              </h2>
              <div role="tablist" className="tabs tabs-bordered">
                {paymentMethods.map((method) => (
                  <React.Fragment key={method.id}>
                    <input
                      type="radio"
                      name="payment_tabs"
                      role="tab"
                      disabled={method.id === 2 || method.id === 3}
                      className={getTabClass(method.id)}
                      aria-label={method.name}
                      checked={selectedTab === method.id}
                      onChange={() => handleTabChange(method.id)}
                    />
                    {selectedTab === method.id && (
                      <div role="tabpanel" className="tab-content pt-2">
                        {/* {method.content} */}
                        <div className="mt-4 space-mono-regular">
                          <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-2">
                            Phone number
                          </label>
                          <input
                          required
                            id="phone"
                            name="phone"
                            type="tel"
                            autoComplete="tel"
                            className="outline-none w-full min-w-0 appearance-none rounded-xl border border-gray-100 bg-white px-[calc(theme(spacing.4)-1px)] py-[calc(theme(spacing[4])-1px)] text-base leading-7 text-gray-900 placeholder-gray-400 shadow-sm focus:border-gray-800 focus:ring-pink-600 sm:w-64 sm:text-sm sm:leading-6 xl:w-full"
                            placeholder="Enter your M-PESA phone number"
                            pattern="\d{10}"
                            title="E.g. 07123456789"
                          />
                        </div>
                        <div className="flex items-center justify-between mt-6">
                          <button type="submit" disabled={selectedTickets.length < 1} className="btn bg-white space-mono-bold basis-full md:basis-8/12 uppercase text-sm py-2.5 px-6 border border-gray-200 rounded-xl text-black font-semibold hover:text-white hover:bg-gradient-to-r from-pink-500 to-purple-800 transition ease-in">Buy ticket</button>
                          <button type="button" className="hidden md:blockbtn btn-outline space-mono-bold bg-white px-6 border border-gray-200 rounded-xl text-black font-semibold hover:text-white hover:bg-gradient-to-r from-pink-500 to-purple-800 transition ease-in"
                            onClick={() => document.getElementById('my_modal_3').showModal()}>
                            Scan QR
                            <lord-icon
                              src="https://cdn.lordicon.com/ggnoyhfp.json"
                              trigger="hover"
                              stroke="regular"
                              colors="primary:#9947b0,secondary:#da2877"
                              style={{ width: "30px", height: "30px" }}
                            >
                            </lord-icon></button>
                        </div>

                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>

          {/* Right Column: Order Summary */}
          <div className="hidden md:block mt-10 lg:mt-0">
            <h2 className="text-lg font-medium text-gray-900 space-mono-bold pb-3">Order Summary</h2>
            <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm space-mono-regular">
              <h3 className="sr-only">Tickets in your cart</h3>
              <ul role="list" className="divide-y divide-gray-200">
                {selectedTickets.map((ticket) => (
                  <li key={ticket.id} className="flex py-6 px-4 sm:px-6">
                    <div className="flex-shrink-0">
                      <img
                        src={ticket.imageSrc}
                        alt={ticket.imageAlt}
                        className="w-20 rounded-md"
                      />
                    </div>

                    <div className="ml-6 flex flex-1 flex-col">
                      <div className="flex">
                        <div className="min-w-0 flex-1">
                          <h4 className="text-sm">
                            <span className="font-medium text-gray-700 space-mono-bold">
                              {ticket.ticketName}
                            </span>
                          </h4>
                          <p className="mt-1 text-sm text-gray-500">
                            Quantity: {ticket.quantity}
                          </p>
                        </div>

                        <div className="ml-4 flow-root flex-shrink-0">
                          <button
                            type="button"
                            className="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500"
                            onClick={() => removeTicket(ticket.id)}
                          >
                            <span className="sr-only">Remove</span>
                            🗑️
                          </button>
                        </div>
                      </div>

                      <div className="flex flex-1 items-end justify-between pt-2">
                        <p className="mt-1 text-sm font-medium text-gray-900">
                          KES {ticket.ticketPrice * ticket.quantity}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>

              <dl className="space-y-6 border-t border-gray-200 py-6 px-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <dt className="text-sm">Subtotal</dt>
                  <dd className="text-sm font-medium text-gray-900">KES {subtotal.toFixed(2)}</dd>
                </div>
                <div className="flex items-center justify-between">
                  <dt className="text-sm">Charges</dt>
                  <dd className="text-sm font-medium text-gray-900">KES {charges.toFixed(2)}</dd>
                </div>
                <div className="flex items-center justify-between">
                  <dt className="text-sm">Taxes</dt>
                  <dd className="text-sm font-medium text-gray-900">{taxes}</dd>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt className="text-base font-medium">Total</dt>
                  <dd className="text-base font-medium text-gray-900">KES {total}</dd>
                </div>
              </dl>

              <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                <button type="button" className="btn bg-white space-mono-bold w-full uppercase text-sm py-2.5 px-6 border border-gray-200 rounded-xl text-black font-semibold hover:text-white hover:bg-gradient-to-r from-pink-500 to-purple-800 transition ease-in"
                  onClick={() => {
                    const element = document.getElementById('proceed-payment');
                    if (element) {
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}>Go to payment</button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <dialog id="my_modal_3" className="modal">
        <div className="modal-box max-w-md">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
          </form>
          <h3 className="font-bold text-lg dm-sans text-center">Use the M-PESA APP <br></br> to scan the QR code below</h3>
          <div className="py-4 justify-self-center">
            <img src={qr} alt="qr" className="h-64 w-auto" />
          </div>
          <div className="flex items-center justify-between gap-4">
            <button type="button" className="btn space-mono-bold basis-1/4 uppercase text-sm py-2.5 px-6 border border-gray-200 rounded-xl text-black font-semibold hover:text-white hover:bg-black transition ease-in">Cancel</button>
            <button type="submit" className="btn bg-white space-mono-bold basis-3/4 uppercase text-sm py-2.5 px-6 border border-gray-200 rounded-xl text-black font-semibold hover:text-white hover:bg-gradient-to-r from-pink-500 to-purple-800 transition ease-in">Confirm Payment</button>
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default EventPurchasePage;
