import React from "react";
import { useLocation } from "react-router-dom";
import PageHeader from '../../components/pageHeader'
import PageFooter from "../../components/pageFooter";

function MainLayout({ children }) {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="bg-white">
      <div>
        <PageHeader activePath={currentPath} />
      </div>
      <div>{children}</div>
      <div>
        <PageFooter />
      </div>
    </div>
  );
}

export default MainLayout;
