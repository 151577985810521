import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import getRequest from "../../fetch/api";

const EventPage = () => {
  const [event, setEvent] = useState(null);
  const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { eventId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let interval; // Define the interval variable here
console.log(eventId)
    // Fetch event details
    fetchEventDetails();

    // Clean up the interval on unmount
    return () => clearInterval(interval);
  }, [eventId]);

  const fetchEventDetails = async () => {
    try {
      const res = await getRequest(`/event/get?eventId=${eventId}`);
      // console.log(res.data.event);
      const event = res.data.event
      setEvent(event);
      setLoading(false);
      initializeCountdown(event);
    } catch (error) {
      console.error("Error fetching event:", error);
      setError("Failed to load event details");
      setLoading(false);
    }
  };

  const initializeCountdown = (event) => {
    // console.log("countdown timer", event)
    if (!event?.ticketSaleEndDate) return;

    const expiryDate = new Date(event.ticketSaleEndDate);
    let interval = setInterval(() => {
      const now = new Date();
      const timeDiff = expiryDate - now;

      if (timeDiff <= 0) {
        clearInterval(interval);
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
        const seconds = Math.floor((timeDiff / 1000) % 60);
        setCountdown({ days, hours, minutes, seconds });
      }
    }, 1000);
  };

  const handleGetTicket = () => {
    navigate(`/eventPurchase/${eventId}`);
  };

  if (loading) {
    return (
      <div className="py-24 h-[70vh] max-w-4xl dm-sans mx-auto text-center flex flex-col items-center justify-center">
        <span className="loading loading-ring loading-4xl text-purple-800"></span>
        <p>Loading event details...</p>
      </div>
    )
  }

  if (error) {
    return (
      <div className="py-24 h-[70vh] max-w-4xl dm-sans mx-auto text-center flex flex-col items-center justify-center">
        <lord-icon
          src="https://cdn.lordicon.com/lltgvngb.json"
          trigger="in"
          delay="1500"
          stroke="light"
          state="hover-oscillate"
          colors="primary:#9947b0,secondary:#da2877"
          style={{ width: "100px", height: "100px" }}
        ></lord-icon>
        <p className="text-red-500 space-mono-regular">{error}</p>
      </div>
    );
  }

  return (
    <div className="relative">
      <div className="mx-auto max-w-6xl lg:grid lg:grid-cols-12 lg:gap-x-8">
        <div className="px-6 pt-12 md:pt-32 lg:col-span-7 lg:px-0 xl:col-span-6 pb-8 md:pb-48">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <div className="flex">
              <div className="uppercase space-mono-bold relative rounded-full py-1 px-1 text-sm leading-6 text-purple-500">
                {event?.eventLocation || "Event"}
              </div>
            </div>
            <h1 className="mt-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl dm-sans">
              {event?.eventName || "Event Name"}
            </h1>
            <p className="mt-6 text-lg leading-7 text-gray-600 space-mono-regular">
              {event?.eventDescription || "Event description goes here."}
            </p>
            <div className="grid grid-flow-col gap-5 text-center auto-cols-max py-6">
              <div className="flex flex-col">
                <span className="countdown font-mono text-5xl">{countdown.days}</span>
                <span className="space-mono-regular">days</span>
              </div>
              <div className="flex flex-col">
                <span className="countdown font-mono text-5xl">{countdown.hours}</span>
                <span className="space-mono-regular">hours</span>
              </div>
              <div className="flex flex-col">
                <span className="countdown font-mono text-5xl">{countdown.minutes}</span>
                <span className="space-mono-regular">min</span>
              </div>
              <div className="flex flex-col">
                <span className="countdown font-mono text-5xl">{countdown.seconds}</span>
                <span className="space-mono-regular">sec</span>
              </div>
            </div>
            <div className="flex items-center gap-x-6">
              <div className="flex gap-2 items-center space-mono-regular justify-start pt-4">
                <button
                  className="uppercase text-xs py-2.5 px-6 border border-gray-200 rounded-full text-black font-semibold hover:text-white hover:bg-gradient-to-r from-pink-500 to-purple-800 transition ease-in"
                  onClick={handleGetTicket}
                >
                  Get Ticket
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0 px-4 md:px-0">
          <img
            className="w-full md:w-[35rem] bg-gray-50 object-fit lg:absolute lg:inset-0 h-auto md:h-[90vh]"
            src={"https://www.eventbookings.com/wp-content/uploads/2023/06/Colorful-Dance-Party-Night-Poster-.jpg" || "https://via.placeholder.com/300"}
            alt={event?.eventName || "Event Image"}
          />
        </div>
      </div>
    </div>
  );
};

export default EventPage;
