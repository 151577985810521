import React from "react";
import qr from '../../assets/img/qr-code.png';


const products = [
  {
    id: 1,
    name: 'Cold Brew Bottle',
    description:
      'This glass bottle comes with a mesh insert for steeping tea or cold-brewing coffee. Pour from any angle and remove the top for easy cleaning.',
    href: '#',
    quantity: 1,
    price: '32.00',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/confirmation-page-05-product-01.jpg',
    imageAlt: 'Glass bottle with black plastic pour top and mesh insert.',
  },
];

const ConfirmationPage = () => {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-3xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="max-w-xl">
          <h1 className="text-base font-medium text-purple-600 space-mono-bold">Payment successful</h1>
          <p className="mt-2 text-4xl font-bold tracking-tight sm:text-5xl dm-sans">Thank You for Your Purchase!</p>
          <p className="mt-2 text-base text-gray-500 space-mono-regular mt-4 leading-7">Dear Benson,<br></br>
            Thank you for purchasing your ticket! We're thrilled to have you join us for SoldOut Extravaganza 2.</p>
        </div>

        <div className="mt-10 border-t border-gray-200">
          <h3 className="sr-only">Items</h3>
          {products.map((product) => (
            <div key={product.id} className="flex space-x-6 border-b border-gray-200 py-10">
              <img
                src={qr}
                alt={product.imageAlt}
                className="h-20 w-20 flex-none rounded-lg bg-gray-100 object-cover object-center sm:h-40 sm:w-40"
              />
              <div className="grid place-content-between pb-6">
                  <h4 className="font-medium text-gray-900">
                    <div className="space-mono-bold">{product.name}</div>
                  </h4>
                  <div className="grid">
                    <dt className="font-medium text-gray-400 space-mono-regular">Ticket Code:</dt>
                    <dd className="text-pink-700 space-mono-bold text-2xl">SOA1279323</dd>
                  </div>
                  <dl className="flex space-x-4 divide-x divide-gray-200 text-sm sm:space-x-6">
                    <div className="flex">
                      <dt className="font-medium text-gray-900 space-mono-bold">Quantity</dt>
                      <dd className="ml-2 text-gray-70 space-mono-regular0">{product.quantity}</dd>
                    </div>
                    <div className="flex pl-4 sm:pl-6">
                      <dt className="font-medium text-gray-900 space-mono-bold">Price</dt>
                      <dd className="ml-2 text-gray-700 space-mono-regular">KES {product.price}</dd>
                    </div>
                  </dl>
              </div>
            </div>
          ))}

          <div className="sm:ml-40 sm:pl-6 space-mono-regular">
            <h3 className="sr-only">Your information</h3>
            <h4 className="sr-only">Payment</h4>
            <dl className="grid grid-cols-2 gap-x-6 border-t border-gray-200 py-10 text-sm">
              <div>
                <dt className="font-medium text-gray-900 space-mono-bold">Payment method</dt>
                <dd className="mt-2 text-gray-700">
                  <p>Lipa na M-PESA</p>
                  <p>
                    <span aria-hidden="true">****</span>
                    <span className="sr-only">Ending in </span>1545
                  </p>
                </dd>
              </div>
            </dl>

            <h3 className="sr-only">Summary</h3>

            <dl className="space-y-6 border-t border-gray-200 pt-10 text-sm">
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900 space-mono-bold">Subtotal</dt>
                <dd className="text-gray-700">KES 36.00</dd>
              </div>
              <div className="flex justify-between">
                <dt className="flex font-medium text-gray-900 space-mono-bold">Charges</dt>
                <dd className="text-gray-700">KES 18.00</dd>
              </div>
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900 space-mono-bold">Taxes</dt>
                <dd className="text-gray-700">KES 5.00</dd>
              </div>
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900 space-mono-bold">Total</dt>
                <dd className="text-gray-900">KES 23.00</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
