import React from "react";
import { useNavigate } from "react-router-dom";

const PageHeader = ({ activePath }) => {
  const navigate = useNavigate();

  const navItems = [
    { name: "Home", path: "/" },
    { name: "Events", path: "/events" },
    { name: "FAQs", path: "/faqs" },
    { name: "Contacts", path: "/contacts" },
  ];

  return (
    <nav className="border-b border-gray-200 w-full bg-white p-4 md:p-0">
      <div className="max-w-6xl mx-auto flex justify-between items-center">
        {/* Logo */}
        <div
          className="navbar-start cursor-pointer monoton-regular text-xl md:text-2xl bg-gradient-to-r from-pink-500 to-purple-800 bg-clip-text text-transparent leading-normal"
          onClick={() => navigate("/")} // Navigate to the home page when the logo is clicked
        >
          soldout africa
        </div>

        <div className="navbar-end ">
          {/* Navigation Links */}
          <div className="gap-12 items-center space-mono-bold h-full">
            <div className="hidden lg:flex gap-8 items-center">
              {navItems.map((item) => (
                <button
                  key={item.path}
                  onClick={() => navigate(item.path)} // Navigate programmatically
                  className={`cursor-pointer uppercase text-xs font-semibold py-8 border-t-4 px-4 transition ease-in ${activePath === item.path
                    ? "border-pink-600 bg-gradient-to-r from-pink-500 to-purple-800 bg-clip-text text-transparent"
                    : "text-gray-500 border-transparent hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-800 hover:bg-clip-text hover:text-transparent"
                    }`}
                >
                  {item.name}
                </button>
              ))}
              {/* Sign In Button */}
              <button
                onClick={() => navigate("/signin")} // Example path for the Sign In page
                className="hidden lg:flex uppercase text-xs py-2.5 px-6 border border-gray-200 rounded-full text-black font-semibold hover:text-white hover:bg-gradient-to-r from-pink-500 to-purple-800 transition ease-in"
              >
                sign in
              </button>
            </div>


          </div>
          <div className="navbar-end dropdown dropdown-bottom dropdown-end flex lg:hidden justify-content-end">
            <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7" />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
              <li><a>Homepage</a></li>
              <li><a>Portfolio</a></li>
              <li><a>About</a></li>
            </ul>
          </div>
        </div>

      </div>
    </nav>
  );
};

export default PageHeader;
