import React from "react";
import { createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/homePage"
import EventPage from "../pages/eventPage"
import EventPurchasePage from "../pages/eventPurchasePage"
import TicketPage from "../pages/ticketPage"
import ConfirmationPage from '../pages/confirmationPage'
import AllEvents from '../pages/allEvents'
import Faqs from '../pages/faqs'
import Contacts from '../pages/contacts'
import MainLayout from "../layouts/MainLayout/MainLayout";

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainLayout children={<HomePage />} />
    },
    {
        path: "/event/:eventId",
        element: <MainLayout children={<EventPage />}/>
    },
    {
        path: "/events",
        element: <MainLayout children={<AllEvents />}/>
    },
    {
        path: "/eventPurchase/:eventId",
        element: <MainLayout children={<EventPurchasePage />}/>
    },
    {
        path: "/confirmation",
        element: <MainLayout children={<ConfirmationPage />}/>
    },
    {
        path: "/faqs",
        element: <MainLayout children={<Faqs />}/>
    },
    {
        path: "/contacts",
        element: <MainLayout children={<Contacts />}/>
    },
    {
        path: "/ticket",
        element: <TicketPage />
    }
])

export default router