import React, { useState, useEffect } from "react";
import getRequest from "../../fetch/api";
import axios from "axios";

let username = '254717286026'
let password =  's0ascAnn3r@56YearsLater!'

const getToken = (username, password) =>{
  return btoa(`${username}:${password}`);
}


const AllEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // const fetchEvents = () => {
      try {
        axios({
          method: "GET",
          url: "https://soldoutafrica.store/api/v1/events/get/all",
          // withCredentials: true,
          headers: {
            // Authorization: getToken(username,password),
            'Access-Control-Allow-Origin': "*",
            "Content-Type": "application/json"
          }
        })
        .then((res)=>{
          console.log("ressssss",res)
        })
        .catch((err)=>{
          console.log("errrrrr", err)
        })


        // const response = await getRequest("/events/get/all");
        // setEvents(response);
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching events:", error);
        // setError("Failed to load events");
        // setLoading(false);
      }
    // };

    // fetchEvents();
  }, events); // Empty dependency array ensures this is called once, similar to componentDidMount

  if (loading) {
    return (
      <div className="bg-white py-24 h-[70vh] max-w-4xl dm-sans mx-auto text-center flex flex-col items-center justify-center gap-8">
        <span className="loading loading-ring loading-4xl text-purple-800"></span>
        <p>Loading event details...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white py-24 h-[70vh] max-w-4xl dm-sans mx-auto text-center flex flex-col items-center justify-center">
        <lord-icon
          src="https://cdn.lordicon.com/lltgvngb.json"
          trigger="in"
          delay="1500"
          stroke="light"
          state="hover-oscillate"
          colors="primary:#9947b0,secondary:#da2877"
          style={{ width: "100px", height: "100px" }}
        ></lord-icon>
        <p className="text-red-500 space-mono-regular">{error}</p>
      </div>
    );
  }

  return (
    <div className="bg-white space-mono-regular">
      <div className="mx-auto max-w-2xl py-14 lg:max-w-6xl">
        <div className="flex justify-end my-6 w-full">
          <label htmlFor="Search" className="sr-only">
            Search event
          </label>
          <input
            type="text"
            name="Search"
            id="Search"
            autoComplete="off"
            className="outline-none max-w-sm min-w-0 appearance-none rounded-full border border-gray-100 bg-white px-[calc(theme(spacing.4)-1px)] py-[calc(theme(spacing[2])-1px)] text-md leading-7 text-gray-900 placeholder-gray-400 shadow-sm focus:border-gray-800 focus:ring-pink-600 sm:w-64 sm:text-sm sm:leading-6 xl:w-full"
            placeholder="Search event"
          />
        </div>
        <div className=" bg-white grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
          {events.map((event) => (
            <a key={event.id} href={event.href} className="group">
              <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg sm:aspect-w-2 sm:aspect-h-3">
                <img
                  src={event.eventPosterUrl} // Changed to reflect actual key
                  alt={event.eventName} // Changed to reflect actual key
                  className="h-full w-full object-cover object-center group-hover:opacity-75"
                />
              </div>
              <div className="mt-4 flex items-center justify-between text-base font-medium text-gray-900">
                <h3 className="font-semibold uppercase">{event.eventName}</h3> {/* Changed to reflect actual key */}
                <p className="text-sm text-gray-500">{event.ticketSaleStartDate}</p> {/* Changed to reflect actual key */}
              </div>
              <p className="text-sm text-gray-500">{event.eventLocation}</p> {/* Changed to reflect actual key */}
              <p className="text-sm text-gray-500">{event.eventDescription}</p> {/* Changed to reflect actual key */}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllEvents;
