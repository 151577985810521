import axios from 'axios';

// Base Axios Instance
const API = axios.create({
//   baseURL: process.env.REACT_APP_BASE_URL || '{{base_url}}', // Use environment variable or default value
  baseURL: 'http://core:1122/api/v1',
  auth: {
    username: '254717286026', // Default username
    password: 's0ascAnn3r@56YearsLater!',    // Default password
  },
});

// Add a request interceptor to add the Authorization header dynamically
API.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('authToken');
      if (token && !config.headers['Authorization']) { // Only add the token if it's not already there
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

// Generic GET Request Function
export const getRequest = async (endpoint, params = {}) => {
  try {
    const response = await API.get(endpoint, { params });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${endpoint}`, error);
    throw error;
  }
};

// Generic POST Request Function
export const postRequest = async (endpoint, data) => {
  try {
    const response = await API.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error(`Error posting data to ${endpoint}`, error);
    throw error;
  }
};

export default API;
