import React, { Component } from "react";
import HomeHero from "../../components/homeHero/homeHero";
import pattern from "../../assets/img/patternpad-2024-11-21-20-03-04.svg"
// import SoaLoading from '../../components/soaLoader'

const products = [
  {
    id: 1,
    name: 'Focus Paper Refill',
    href: '#',
    price: '$13',
    description: '3 sizes available',
    imageSrc: 'https://www.eventbookings.com/wp-content/uploads/2023/06/Brothers-Lounge-Bar-Poster.jpg',
    imageAlt: 'Person using a pen to cross a task off a productivity paper card.',
  },
  {
    id: 2,
    name: 'Focus Card Holder',
    href: '#',
    price: '$64',
    description: 'Walnut',
    imageSrc: 'https://www.eventbookings.com/wp-content/uploads/2023/06/Afrobeats-Poster-1.jpg',
    imageAlt: 'Paper card sitting upright in walnut card holder on desk.',
  },
  {
    id: 3,
    name: 'Focus Carry Case',
    href: '#',
    price: '$32',
    description: 'Heather Gray',
    imageSrc: 'https://www.eventbookings.com/wp-content/uploads/2023/06/White-Black-Red-Modern-Art-Exhibition-Poster-3.jpg',
    imageAlt: 'Textured gray felt pouch for paper cards with snap button flap and elastic pen holder loop.',
  },
  {
    id: 4,
    name: 'Focus Carry Case',
    href: '#',
    price: '$32',
    description: 'Heather Gray',
    imageSrc: 'https://www.eventbookings.com/wp-content/uploads/2023/06/White-Black-Red-Modern-Art-Exhibition-Poster-3.jpg',
    imageAlt: 'Textured gray felt pouch for paper cards with snap button flap and elastic pen holder loop.',
  },
  {
    id: 5,
    name: 'Focus Paper Refill',
    href: '#',
    price: '$13',
    description: '3 sizes available',
    imageSrc: 'https://www.eventbookings.com/wp-content/uploads/2023/06/Brothers-Lounge-Bar-Poster.jpg',
    imageAlt: 'Person using a pen to cross a task off a productivity paper card.',
  },
  {
    id: 6,
    name: 'Focus Card Holder',
    href: '#',
    price: '$64',
    description: 'Walnut',
    imageSrc: 'https://www.eventbookings.com/wp-content/uploads/2023/06/Afrobeats-Poster-1.jpg',
    imageAlt: 'Paper card sitting upright in walnut card holder on desk.',
  },
]

class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      loading: false
    }
  }
  render() {
    return (
      <div className="relative bg-white px-4 md:px-0">
        <HomeHero />
        <div className="bg-white ">
          <div className="mx-auto max-w-2xl py-10 lg:max-w-6xl">
            <h2 id="products-heading" className="sr-only">
              Events
            </h2>
            <div className="text-3xl lg:4/5 text-gray-800 dm-sans pb-6">
              Popular Events
            </div>

            <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
              {products.map((product) => (
                <a key={product.id} href={product.href} className="group">
                  <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg sm:aspect-w-2 sm:aspect-h-3">
                    <img
                      src={product.imageSrc}
                      alt={product.imageAlt}
                      className="h-full w-full object-cover object-center group-hover:opacity-75"
                    />
                  </div>
                  <div className="mt-4 flex items-center justify-between text-base font-medium text-gray-900">
                    <h3 className="space-mono-bold">{product.name}</h3>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="relative overflow-hidden bg-white">
          <div className="pt-4 pb-80 sm:pb-40 md:pt-40 lg:pb-48">
            <div className="relative mx-auto max-w-6xl sm:static">
              <div className="sm:max-w-lg">
                <h1 className="font md:text-3xl font-bold tracking-tight text-gray-900 text-5xl dm-sans">
                  Experience the Future, One Event at a Time
                </h1>
                <p className="mt-4 text-md text-gray-500 space-mono-regular">
                  Discover groundbreaking innovations, connect with thought leaders, and immerse yourself in events that inspire, engage, and redefine what's possible. Join us in celebrating creativity, technology, and the power of human connection.
                </p>
              </div>
              <div>
                <div className="mt-10">
                  {/* Decorative image grid */}
                  <div
                    aria-hidden="true"
                    className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-6xl"
                  >
                    <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                      <div className="flex items-center space-x-6 lg:space-x-8">
                        <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                          <div className="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                            <img
                              src="https://www.eventbookings.com/wp-content/uploads/2023/06/Brothers-Lounge-Bar-Poster.jpg"
                              alt=""
                              className="h-full w-full object-cover object-center"
                            />
                          </div>
                          <div className="h-64 w-44 overflow-hidden rounded-lg">
                            <img
                              src="https://www.eventbookings.com/wp-content/uploads/2023/06/Afrobeats-Poster-1.jpg"
                              alt=""
                              className="h-full w-full object-cover object-center"
                            />
                          </div>
                        </div>
                        <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                          <div className="h-64 w-44 overflow-hidden rounded-lg">
                            <img
                              src="https://www.eventbookings.com/wp-content/uploads/2023/06/White-Black-Red-Modern-Art-Exhibition-Poster-3.jpg"
                              alt=""
                              className="h-full w-full object-cover object-center"
                            />
                          </div>
                          <div className="h-64 w-44 overflow-hidden rounded-lg">
                            <img
                              src="https://www.eventbookings.com/wp-content/uploads/2023/06/5.1Arabian-Hookah-Night-Event-Poster-3.jpg"
                              alt=""
                              className="h-full w-full object-cover object-center"
                            />
                          </div>
                          <div className="h-64 w-44 overflow-hidden rounded-lg">
                            <img
                              src="https://www.eventbookings.com/wp-content/uploads/2023/06/POA-Jazz-Festival.jpg"
                              alt=""
                              className="h-full w-full object-cover object-center"
                            />
                          </div>
                        </div>
                        <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                          <div className="h-64 w-44 overflow-hidden rounded-lg">
                            <img
                              src="https://www.eventbookings.com/wp-content/uploads/2023/06/Wrecked-poster-indicate.jpeg"
                              alt=""
                              className="h-full w-full object-cover object-center"
                            />
                          </div>
                          <div className="h-64 w-44 overflow-hidden rounded-lg">
                            <img
                              src="https://www.eventbookings.com/wp-content/uploads/2023/06/White-Black-Red-Modern-Art-Exhibition-Poster-3.jpg"
                              alt=""
                              className="h-full w-full object-cover object-center"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="space-mono-bold uppercase text-xs py-2.5 px-6 border border-gray-200 rounded-full text-black font-semibold hover:text-white hover:bg-gradient-to-r from-pink-500 to-purple-800 transition ease-in">
                    Browse events
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="mx-auto max-w-2xl py-10 lg:max-w-6xl">
            <h2 id="products-heading" className="sr-only">
              Events
            </h2>
            <div className="text-3xl lg:4/5 text-gray-800 dm-sans pb-6">
              New Events
            </div>

            <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
              {products.slice(0, 3).map((product) => (
                <a key={product.id} href={product.href} className="group">
                  <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg sm:aspect-w-2 sm:aspect-h-3">
                    <img
                      src={product.imageSrc}
                      alt={product.imageAlt}
                      className="h-full w-full object-cover object-center group-hover:opacity-75"
                    />
                  </div>
                  <div className="mt-4 flex items-center justify-between text-base font-medium text-gray-900">
                    <h3 className="space-mono-bold">{product.name}</h3>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="mx-auto max-w-6xl md:pt-16 pb-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="space-mono-bold text-4xl font-bold tracking-tight text-gray-900">
              Bringing people together
              <br />
              because life's better celebrated.
            </h2>
            <div className="mt-10 flex items-center justify-end md:justify-start gap-x-6 lg:mt-0 lg:flex-shrink-0">
              <lord-icon
                src="https://cdn.lordicon.com/mhjhxcxu.json"
                trigger="hover"
                stroke="light"
                state="hover-erase"
                colors="primary:#9947b0"
                style={{ width: "250px", height: "250px" }}>
              </lord-icon>
              {/* <img src={pattern} alt="/" className="h-96 w-96 rounded-full" /> */}
            </div>
          </div>
        </div>
        <div className="bg-white mx-auto max-w-6xl space-mono-regular">
          <p class="mt-6 text-md leading-8 text-gray-600">At SoldOutAfrica, we believe that every event is 
            more than just a gathering—it's a powerful opportunity to create lasting memories, celebrate life's 
            milestones, and connect with the people who matter most. Whether it's an intimate affair, a corporate 
            showcase, or a grand celebration, we see every occasion as a chance to turn dreams into reality. 
            Our team is dedicated to weaving together creativity, precision, and passion, ensuring that every 
            detail reflects your vision and every moment feels truly unforgettable. From the first idea to the 
            final applause, we are by your side to make the planning process as seamless as the event itself.
            At the heart of everything we do is a commitment to creating extraordinary experiences that 
            leave lasting impressions. We understand that every story is unique, which is why we take the 
            time to understand your goals, your personality, and your audience, crafting events that feel 
            personal and extraordinary. Let us handle the logistics while you focus on the magic of the 
            moment. Because when it comes to events that inspire, captivate, and connect, SoldOutAfrica 
            is where your vision comes to life and memories are made to last forever.</p>
        </div>
        <div className="bg-white pt-12">
          <div className="overflow-hidden pt-32 sm:pt-14">
            <div className="">
              <div className="mx-auto max-w-6xl">
                <div className="relative pt-48 pb-16 sm:pb-24">
                  <div>
                    <h2 id="sale-heading" className="dm-sans md:text-4xl font-bold tracking-tight text-purple-600 text-5xl">
                      Dream it, plan it, <br className="md:hidden"></br> live it
                      <br />
                      —right here.
                    </h2>
                    <div className="mt-6 text-base">
                      <button
                        type="button"
                        className="space-mono-bold uppercase text-xs py-2.5 px-6 border border-gray-200 rounded-full text-black font-semibold hover:text-white hover:bg-gradient-to-r from-pink-500 to-purple-800 transition ease-in">
                        Reach out
                      </button>
                    </div>
                  </div>

                  <div className="absolute -top-32 left-1/2 -translate-x-1/2 transform sm:top-6 sm:translate-x-0">
                    <div className="ml-24 flex min-w-max space-x-6 sm:ml-3 lg:space-x-8">
                      <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                        <div className="flex-shrink-0">
                          <img
                            className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                            src="https://www.eventbookings.com/wp-content/uploads/2023/06/POA-Jazz-Festival.jpg"
                            alt=""
                          />
                        </div>

                        <div className="mt-6 flex-shrink-0 sm:mt-0">
                          <img
                            className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                            src="https://www.eventbookings.com/wp-content/uploads/2023/06/Wrecked-poster-indicate.jpeg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                        <div className="flex-shrink-0">
                          <img
                            className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                            src="https://www.eventbookings.com/wp-content/uploads/2023/06/White-Black-Red-Modern-Art-Exhibition-Poster-3.jpg"
                            alt=""
                          />
                        </div>

                        <div className="mt-6 flex-shrink-0 sm:mt-0">
                          <img
                            className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                            src="https://www.eventbookings.com/wp-content/uploads/2023/06/White-Black-Red-Modern-Art-Exhibition-Poster-3.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                        <div className="flex-shrink-0">
                          <img
                            className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                            src="https://www.eventbookings.com/wp-content/uploads/2023/06/Afrobeats-Poster-1.jpg"
                            alt=""
                          />
                        </div>

                        <div className="mt-6 flex-shrink-0 sm:mt-0">
                          <img
                            className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                            src="https://www.eventbookings.com/wp-content/uploads/2023/06/5.1Arabian-Hookah-Night-Event-Poster-3.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HomePage;
