import React, { Component } from "react";

class Faqs extends Component {
  state = {
    faqs: [
      {
        id: 1,
        question: "How do I book tickets for an event?",
        answer:
          "Booking tickets is simple! Browse the events listed on our website, select the event you’re interested in, choose your preferred ticket type, and proceed to checkout.",
      },
      {
        id: 2,
        question: "Can I book multiple tickets at once?",
        answer:
          "Yes, you can book multiple tickets in a single transaction. Just select the quantity of tickets during the booking process.",
      },
      {
        id: 3,
        question: "What payment methods do you accept?",
        answer:
          "We accept all major credit and debit cards, mobile payment options, and digital wallets. The payment methods may vary depending on your location.",
      },
      {
        id: 4,
        question: "Do I need to create an account to book tickets?",
        answer:
          "Creating an account is recommended as it allows you to manage your bookings, receive updates, and access your ticket history. However, you can also book as a guest.",
      },
      {
        id: 5,
        question: "What happens after I book a ticket?",
        answer:
          "Once your booking is confirmed, you will receive a confirmation email with your ticket(s) attached. You can also access your tickets under 'My Bookings' if you have an account.",
      },
      {
        id: 6,
        question: "Can I cancel or modify my booking?",
        answer:
          "Cancellations and modifications depend on the event organizer's policies. Check the event details or contact our support team for assistance.",
      },
      {
        id: 7,
        question: "How do I access virtual events after booking?",
        answer:
          "For virtual events, you’ll receive a link and instructions via email after booking. Ensure you have a stable internet connection to join the event.",
      },
      {
        id: 8,
        question: "Are there any additional fees when booking tickets?",
        answer:
          "There may be a small service fee or processing fee added to the ticket price. These fees will be displayed before you complete your purchase.",
      },
      {
        id: 9,
        question: "What if I don’t receive my ticket confirmation email?",
        answer:
          "If you don’t receive a confirmation email within a few minutes, check your spam folder. If it’s still not there, contact our support team for assistance.",
      },
      {
        id: 10,
        question: "Can I gift a ticket to someone else?",
        answer:
          "Yes, you can purchase tickets as a gift. Just enter the recipient’s details during the booking process, or transfer the ticket to their email after purchase.",
      },
    ],
  };

  render() {
    const { faqs } = this.state;

    return (
      <div className="bg-white relative mx-auto max-w-4xl py-14">
        <div className="join join-vertical w-full">
        <h2 className="text-2xl font-bold text-center mb-10 space-mono-bold text-black dark:text-white">Frequently Asked Questions</h2>
        {faqs.map((faq) => (
          <div key={faq.id} className="collapse collapse-arrow join-item border-b border-gray-100 py-2">
            <input type="radio" name="faqs-accordion" />
            <div className="collapse-title text-lg font-medium dm-sans">{faq.question}</div>
            <div className="collapse-content space-mono-regular text-gray-500">
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
      </div>
      
    );
  }
}

export default Faqs;
