import React, { Component } from "react";

class PageFooter extends Component {
  render() {
    return (
      <footer className="bg-white px-4 lg:px-0" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-6xl pb-8 pt-12">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8">
              <div className="cursor-pointer monoton-regular text-3xl bg-gradient-to-r from-pink-500 to-purple-800 bg-clip-text text-transparent leading-normal">
                soldout africa
              </div>
              <p className="text-sm leading-6 text-gray-600 space-mono-regular">
                Your gateway to unforgettable experiences! From concerts and festivals to conferences and shows, we connect you to the events you love. Book your tickets now and let the moments begin!              </p>
              <div className="flex space-x-6 items-center">
                <lord-icon
                  src="https://cdn.lordicon.com/cuwcpyqc.json"
                  trigger="hover"
                  stroke="light"
                  colors="primary:#9947b0,secondary:#da2877"
                  style={{ width: "45px", height: "45px" }}>
                </lord-icon>
                <lord-icon
                  src="https://cdn.lordicon.com/lplofcfe.json"
                  trigger="hover"
                  stroke="light"
                  colors="primary:#9947b0,secondary:#da2877"
                  style={{ width: "45px", height: "45px" }}>
                </lord-icon>
                <lord-icon
                  src="https://cdn.lordicon.com/yizwahhw.json"
                  trigger="hover"
                  stroke="light"
                  colors="primary:#9947b0,secondary:#da2877"
                  style={{ width: "40px", height: "40px" }}>
                </lord-icon>
                <lord-icon
                  src="https://cdn.lordicon.com/lyjuidpq.json"
                  trigger="hover"
                  stroke="light"
                  state="morph-circle"
                  colors="primary:#9947b0,secondary:#da2877"
                  style={{ width: "40px", height: "40px" }}>
                </lord-icon>
              </div>
            </div>
            <div className="mt-16 grid grid-cols-2 gap-0 xl:col-span-2 xl:mt-0">
              {/* <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">Solutions</h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {navigation.solutions.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">Support</h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {navigation.support.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div> */}
              <div className="hidden md:block"></div>
              <div className="mt-0 lg:mt-10 xl:mt-0 self-center space-mono-regular col-span-2 md:col-span-1">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Subscribe to our newsletter</h3>
                <p className="mt-2 text-sm leading-6 text-gray-600">
                  The latest news, articles, and resources, sent to your inbox weekly.
                </p>
                <form className="mt-6 sm:flex sm:max-w-md">
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    type="email"
                    name="email-address"
                    id="email-address"
                    autoComplete="email"
                    required
                    className="outline-none w-full min-w-0 appearance-none rounded-xl border border-gray-100 bg-white px-[calc(theme(spacing.4)-1px)] py-[calc(theme(spacing[2])-1px)] text-base leading-7 text-gray-900 placeholder-gray-400 shadow-sm focus:border-gray-800 focus:ring-pink-600 sm:w-64 sm:text-sm sm:leading-6 xl:w-full"
                    placeholder="Enter your email"
                  />
                  <div className="mt-4 rounded-md sm:mt-0 sm:ml-4 sm:flex-shrink-0">
                    <button
                      type="submit"
                      className="uppercase text-xs py-2.5 px-6 border border-gray-200 rounded-full text-black font-semibold hover:text-white hover:bg-gradient-to-r from-pink-500 to-purple-800 transition ease-in">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
            <p className="space-mono-regular text-xs leading-5 text-gray-500">&copy; 2024 SoldOutAfrica, Inc. All rights reserved.</p>
          </div>
        </div>
      </footer>
    )
  }
}

export default PageFooter;
